import {Component, ElementRef, HostListener, Input, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {BreadcrumbService} from './Components/ctrm-breadcrumb/breadcrumb.service';
import {CommonService} from './services/common.service';
import {Subscription} from 'rxjs';
import {Breadcrumb} from './Components/ctrm-breadcrumb/breadcrumb.model';
import {environment} from '../environments/environment';
import {AccessPolicyService} from "./services/accesspolicy.service";

export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    title = 'web1';
    display: boolean = true;
    islogin: boolean = false;
    isLoading: boolean = false;
    systemSettingActive:boolean = false;
    visval: any = 1;
    nametoshow: string = '';
    private appLabel:{[key:string]:string} = {'ctrm':'Ctrm','treasury':'Treasury','ois':'OIS'};
    applications: any[] =[];
    name: any = '';
    email: any = '';
    showLogout: boolean = false;
    showSettings: boolean = false;
    subscription: Subscription;
    megaMenu: any[] = [];
    tradeMegaMenu: any[] = [];
    modalState: boolean = false;
    masterList: Breadcrumb[] = [];
    tradeList: Breadcrumb[] = [];
    readAccessRouter: string[] = [];
    showLanding: boolean = false;
    menuLeft: any = '0px';
    megaMenuDiv: HTMLDivElement;
    modalUserProfile: boolean = false;
    notifFromWebSocket;
    allNotifications: any[] = [];
    notifPanelDisplay: boolean = false;
    notificationLoadingFlag: boolean = false;
    newNotif: boolean = false;
    selectedMenuItem:string = '';
    selectedMenuItemElement:HTMLElement;
    mapKeys:any[] =[];
    hoverdMenu:string = '';
    toggle:boolean=false;
    isDeveloperMode = false;
    parameterFunction:{[key:string]:Function} = {};
    private excludedRouterForAccessPolicy:string[] = ['/','/dashboard','/login']

    constructor(private route: Router,private activatedRoute:ActivatedRoute,private accessService:AccessPolicyService,public breadCrumbService: BreadcrumbService, private messageService: MessageService, public commonService: CommonService) {
        this.subscription = route.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                browserRefresh = !route.navigated;
            } else if (event instanceof NavigationEnd) {
                var routeURI = this.route.url.split('?')[0];
                var lastIndex = routeURI.lastIndexOf("/");
                if (lastIndex > 0 && (lastIndex + 1) < routeURI.length) {
                    routeURI = routeURI.substring(0, lastIndex);
                }
                if(!this.excludedRouterForAccessPolicy.includes(routeURI)) {
                    this.accessService.setRoute(routeURI);
                }
            }
        });
        this.isDeveloperMode =localStorage.getItem("developer") !== null && localStorage.getItem("developer") !== undefined? localStorage.getItem("developer") == 'true':false;       // let stompClient = this.webSocketService.connect();
        // let _this = this;
        // stompClient.connect({}, frame => {
        //     // Subscribe to notification topic
        //     stompClient.subscribe('/topic/notification', notifications => {
        //         // Update notifications attribute with the recent messsage sent from the server
        //         let notfication = JSON.parse(notifications['body']);
        //         let message:string = notfication['message'];
        //         if(message === 'Role Updated') {
        //             let allowedroles= notfication['allowedRoles'];
        //             let roles:string[] = _this.commonService.getFromStorage('roles');
        //             if(roles !== null && roles !== undefined && roles.length > 0 ) {
        //                 if(roles.includes(allowedroles)) {
        //                     _this.updateUserPolicy(true);
        //                 }
        //             }
        //         } else if(message.includes("User Updated")) {
        //             let user = message.split(":").pop();
        //             let userName:string = _this.commonService.getFromStorage('userName');
        //             if(userName === user) {
        //                 let roles:string = notfication['allowedRoles'];
        //                 let splitRoles:string[] = roles.split(",");
        //                 this.commonService.saveToStorage('roles', splitRoles);
        //                 _this.updateUserPolicy(true);
        //             }
        //         } else {
        //             this.applyNewNotifications(notifications);
        //         }
        //     });
        // });
        this.commonService.getJSONByFile("accessPolicy.json").subscribe((data: any) => {
            this.accessService.baseAccessPolicy = data['policy'];
            this.accessService.baseAccessPolicyVersion = data['version'];
        });

    }

    private getApplicationList() {
        let subscription = localStorage.getItem("subscription");
        this.applications = [];
        if(subscription !== null && subscription !== undefined && subscription !== 'null') {
            let json = JSON.parse(subscription);
            let selected:string[] = json['selected'];
            let _this = this;
            selected.forEach(function(app) {
                _this.applications.push({label:_this.appLabel[app],value:app,selected:(app === 'ctrm')})
            });
        } else {
            this.applications.push({label:'Ctrm',value:'ctrm',selected:true});
        }
    }

    private updateUserPolicy(showNotif:boolean = false) {
        let _this = this;
        let tenantId = this.commonService.getTenantId();
        if(tenantId !== null && tenantId !== undefined && tenantId.length > 0 ) {
            this.commonService.getJSONByURL(environment.base_url_new + `/api-iam/api/rolemaster/v1/getlatestpolicyforuserid?tenantId=${tenantId}&username=${this.commonService.getFromStorage('userName')}`).subscribe((next) => {
                _this.commonService.saveAccessPolicy(true, next);
                _this.accessService.reloadAccessPolicy(true, true);
                if (showNotif) {
                    _this.messageService.add({summary: 'Access Policy Updated !', severity: 'info'});
                }
            });
        }
    }

    @HostListener('window:resize', [])
    public onResize() {
        this.calculateLeft(this.hoverdMenu);
    }

    calculateLeft(id:string) {
        this.hoverdMenu = id;
        let divElement:HTMLElement = document.getElementById(id);
        if (divElement !== undefined && divElement !== null) {
            let menuLeft = 0;
            let divWidth = divElement.offsetWidth;
            let currentLeft = divElement.parentElement.offsetLeft;
            let screenWidth = window['visualViewport'].width;
            if (divWidth + currentLeft > screenWidth) {
                menuLeft = ((screenWidth - (divWidth + currentLeft)) - 10);
            }
            this.menuLeft = (menuLeft - 70) + 'px';
        }
    }

    ngOnInit() {
        this.breadCrumbService.addMenu();
        let url = window.location.href;
        if (!url.includes('#/signup')) {
            if (this.checkLoginStatus()) {
                this.openDashboard();
            } else {
                this.showLanding = true;
            }
            this.route.events.subscribe(value => {
                this.checkLoginStatus();
            });
        }
        this.parameterFunction['cponboard'] = () => {
            let roles :string = this.commonService.getFromStorage('roles');
            let roleAarray = roles.split(',')
                if(roleAarray!= null && roleAarray!== undefined && roleAarray.length>0){
                    if(roleAarray.includes('Trader')) {
                        return "?tabId=Draft";
                    }else {
                        return "?tabId=Team Task"
                    }
                }
        }
        this.parameterFunction['tradeSwitch'] = () => {
                    return "?tabId=Draft";
        }
        this.parameterFunction['actualizationSwitch']=()=>{
            return "?tabId=actulization";
        }
        this.getApplicationList();
        this.mapKeys.push({id: 'trade', key: this.commonService.TRADE_MAP_KEY, title: 'Trade'});
        this.mapKeys.push({id: 'operation', key: this.commonService.OPERATION_MAP_KEY, title: 'Operations & Accounts'});
        this.mapKeys.push({id: 'risk', key: this.commonService.RISK_MAP_KEY, title: 'Risk'});
        this.mapKeys.push({id: 'finance', key: this.commonService.FINANCE_MAP_KEY, title: 'Finance'});
        // this.mapKeys.push({id:'master',key:this.commonService.MASTER_MAP_KEY,title:'Master Settings'});
        this.updateUserPolicy();
        this.updateUI();
    }

    changeState() {
        this.isLoading = !this.isLoading;
    }

    changeRoute() {
        this.breadCrumbService.clearAll();
        this.route.navigate(['/dashboard']);
    }

    openDashboard() {
        if (this.checkLoginStatus()) {
            //this.route.navigate(['/tree']);
        }
    }

    checkLoginStatus() {
        let userid = this.commonService.getFromStorage('userid');
        if (userid !== null && userid !== undefined && userid.length > 0) {
            this.showLanding = false;
            this.islogin = true;
            this.getNameIcon();
        } else {
            this.islogin = false;
        }
        return this.islogin;
    }

    logout() {
        let  _this = this;
        let obj = {
            "username" : this.commonService.getFromStorage("loginType")==='BusinessUser'?this.commonService.getFromStorage("userName"):this.commonService.getFromStorage("email"),
            "loginType" :  this.commonService.getFromStorage("loginType")
        };
        this.commonService.post(environment.base_url_new + '/api-iam/api/user-session/v1/logout?tenantId=' + this.commonService.getTenantId(), obj).subscribe(next => {
            _this.islogin = false;
            _this.visval = true;
            _this.showLogout = !this.showLogout;
           _this.commonService.clearStorage();
           _this.onClickLogin();
        }, error => {
            this.messageService.add({summary: error['error']['status'], severity: 'error'});
        });

    }

    getNameIcon() {
        this.name = this.commonService.getFromStorage('userName');
        this.email = this.commonService.getFromStorage('email');
        let name1 = this.name.toUpperCase();
        let sp = name1.split(' ');
        this.nametoshow = '';
        this.nametoshow = sp[0][0];
    }

    focusout() {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    goToMaster(route) {
        this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');

        if (this.readAccessRouter.includes(route.route)) {
            this.breadCrumbService.updateBreadcrumb(route.route, this.commonService.MASTER_MAP_KEY);
        }
    }

    goToFinance(route) {
        this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');
        if (!this.readAccessRouter.includes(route.route)) {
            return;
        }
        this.breadCrumbService.updateBreadcrumb(route.route, this.commonService.FINANCE_MAP_KEY);
    }

    getActive() {
        if (this.route.url == '/dashboard') {
            return true;
        } else {
            return false;
        }
    }

    goToTradeOptions(route) {
        this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');
        if (!this.readAccessRouter.includes(route.route)) {
            return;
        }
        if(route.switchApp !== null && route.switchApp!== undefined && route.switchApp ) {
            window.location.href = route.route;
        }
         else if (route.route == '/deal-slip') {
            if(route.name == 'Purchase Deal'){
                window.location.href = '/ctrm-v2/#/deal-slip/BUY'
            }else{
                window.location.href = '/ctrm-v2/#/deal-slip/SELL'
            }
        } else {
            this.breadCrumbService.updateBreadcrumb(route.route, this.commonService.TRADE_MAP_KEY);
        }
    }

    closeModal(value) {
        this.modalState = false;
    }

    onSave(value) {
        this.modalState = false;
        this.messageService.add({
            key: 'toastMessage',
            severity: 'info',
            summary: 'Trade Created Successfully',
            detail: 'Trade  ' + value['data']['tradeId'] + ' created successfully.'
        });
        this.route.navigate(['/trade']);
    }

    isDotVisible(name) {
        let arr: string[] = this.commonService.getFromStorage('Bcrumb_added_local');
        if (arr !== undefined && arr.length > 0) {
            return arr[0] === name;
        }
        return false;
    }

    goToOpAccOptions(route) {
        this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');
        if (!this.readAccessRouter.includes(route.route)) {
            return;
        }
        this.breadCrumbService.updateBreadcrumb(route.route, this.commonService.OPERATION_MAP_KEY);
    }

    goToRiskOptions(route) {
        this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');
        if (!this.readAccessRouter.includes(route.route)) {
            return;
        }
        this.breadCrumbService.updateBreadcrumb(route.route, this.commonService.RISK_MAP_KEY);
    }

    activemenucss(item) {
        if(this.isRouteEnabled(item)) {
            return 'menuli';
        } else {
            return 'greyoutmenu';
        }
    }

    isRouteEnabled(item) {
        if(environment.disabled_routes !== null && environment.disabled_routes !== undefined && environment.disabled_routes.includes(item.route)) {
            return false;
        }
        this.readAccessRouter = this.commonService.getFromStorage('readAccessRouter');
        if(this.readAccessRouter === null || this.readAccessRouter === undefined) {
            this.readAccessRouter = [];
        }
        return this.readAccessRouter.includes(item.route);
    }

    onClickLogin() {
        if (window.location.origin.includes('localhost')) {
            this.showLanding = false;
            this.route.navigate(['/login']);
        } else {
            window.location.href = '/auth/';
        }

    }

    openUserProfile() {
        this.modalUserProfile = true;
        this.showLogout = false;
    }

    closeManageProfile() {
        this.modalUserProfile = false;
    }


    //Added the listner to detect the clicking the outside of notification div to close it.
    @HostListener('document:click', ['$event']) onDocumentClick(event) {
        this.notifPanelDisplay = false;
    }

    //Open Notification Panel and call the API to fetch all the notifications.
    //API call will not happen if the allNotifications array is not Empty.
    openNotificationsPanel(event) {
        this.newNotif = false;
        event.stopPropagation();
        this.notifPanelDisplay = !this.notifPanelDisplay;
        this.getNotificationsFromDB();
    }

    openUserMenu(event) {
        event.stopPropagation();
        this.showLogout = !this.showLogout;
    }

    getNotificationsFromDB() {
        this.commonService.getFromUrl(environment.base_url + '/api/notification/v1/getallnotifications?tenantId='+this.commonService.getTenantId()).subscribe((notif: any) => {
            this.allNotifications = notif;
            this.notificationLoadingFlag = false;
        });
    }

    applyNewNotifications(notifications: any) {
        this.newNotif = true;
    }

    onActionClick(notif: any) {
        this.notifPanelDisplay = false;
        this.commonService.setNotificationViewStatus(notif);
    }

    checkForNotificationBell() {
        return this.route.url !== '/dashboard';
    }

    onmouseover(navbarDropdownMenuLink: string, id:string) {
        let el: HTMLElement = document.getElementById(id);
        if (this.selectedMenuItem !== null && this.selectedMenuItem !== undefined && this.selectedMenuItem !== navbarDropdownMenuLink) {
            if (this.selectedMenuItem.length > 0) {
                document.getElementById(this.selectedMenuItem).setAttribute("aria-expanded", String(false));
                this.selectedMenuItemElement.classList.remove('show');
            }
            this.selectedMenuItem = navbarDropdownMenuLink;
            this.selectedMenuItemElement = el;
        }
    }

    switchApp(appName:any){
        if(appName==='ctrm'){
            window.location.href = '/#/dashboard';
        } else if(appName==='treasury'){
            window.location.href = '/ui-treasury/';
        } else if(appName==='business-master'){
            localStorage.setItem("bm-version", 'v1');
            window.location.href = '/ui-bm/#/company';
        } else if(appName==='ois'){
            window.location.href = '/ui-ois/';
        } else if(appName==='system-setting'){
            window.location.href = '/ui-bm/#/globalIndicators';
        } else if(appName==='system-setting-beta'){
            window.location.href = '/ui-system-settings/#/globalIndicators';
        }else if(appName==='business-master-config'){
            localStorage.setItem("bm-version", 'v2');
            window.location.href = '/ui-business-master/#/company';
        }
    }

    onmouseoverapp(app:any) {
        let img:HTMLElement = document.getElementById(app['value']);
        if(app.selected){
            img.setAttribute('src','../assets/images/svg/switch-tick.svg');
        }else{
            img.setAttribute('src','../assets/images/svg/switch-tick-active.svg');
        }

    }

    onmouseoutapp(app:any) {
        let img:HTMLElement = document.getElementById(app['value']);
        img.setAttribute('src','../assets/images/svg/switch-tick.svg');
    }

    openSettings(event) {
        event.stopPropagation();
        this.showSettings = !this.showSettings;
    }


    private updateUI() {
        let readAccessRouter:string[] = []
        let accessString:string = this.commonService.getFromStorage('readAccessRouter');
        if(accessString === null || accessString === undefined) {
            readAccessRouter = [];
        } else {
            readAccessRouter = accessString.split(",");
        }
        let system_setting_list:string[] = ['/globalIndicators','/transactionReference','/documentTemplateMaster','/usermaster','/usersession','/rolemaster','/apiconfig','/approvalconfiguration','/notificationsettings','/tenantconfig'];
        let list = readAccessRouter.filter(val => system_setting_list.includes(val));
        this.systemSettingActive = (list.length > 0)
    }

    getParams(item: any) {
        if(item.paramsFunction !== null && item.paramsFunction !== undefined) {
            let func = this.parameterFunction[item.paramsFunction];
            return func();
        } else {
            if(item.params !== null && item.params !== undefined) {
                let paramString = "";
                let keys = Object.keys(item.params);
                keys.forEach((key) => {
                    if(paramString.length == 0) {
                        paramString+="?";
                    } else {
                        paramString+="&";
                    }
                    paramString+= key+"="+item.params[key];
                })
                return paramString;
            }
            return '';
        }
    }

    copyToClipboard() {
        const textarea = document.createElement('textarea');
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        textarea.value = JSON.stringify(localStorage);
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            const successful = document.execCommand('copy');
            if(successful)  {
                this.commonService.postMessage('Copied Local Storage','Local Storage Copied to Clipboard','info');
            }
        } catch (error) {
        } finally {
            document.body.removeChild(textarea);
        }
    }

    pasteToClipboard() {
        navigator.clipboard.readText()
            .then(text => {
                let json = JSON.parse(text);
                localStorage.clear();
                Object.keys(json).forEach(key => {
                    localStorage.setItem(key, json[key]);
                 });
                this.commonService.postMessage('Pasted Local Storage','Local Storage Pasted to Clipboard','info');
            })
            .catch(error => {
                console.error('Failed to read from clipboard:', error);
            });
    }
}



