import {Component, HostListener, Input, OnInit, ViewChildren} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {MessageService, Table} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {Router} from '@angular/router';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {KeyValue} from '../../grid/key-value.model';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MasterService} from '../../masters/services/MasterService';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import {FormControlServiceService} from '../../services/form-control-service.service';


@Component({
  selector: 'app-eod',
  templateUrl: './eod.component.html',
  styleUrls: ['./eod.component.css']
})
export class EodComponent implements OnInit {
  eodData: any[] = [];
  selectedJobs: any[] = [];
  eodCols: any[] = [];
  refresh: boolean = false;
  validationSections: any = {};
  eodDetailCols: any[] = [];
  eodFormGroup:FormGroup;
  inprogressEodRunObject:any = {};
  eodHistoryData: any[] = [];
  secondLevel: boolean = false;
  eodDetailData: any[] = [];
  secondLevelTitle: string = '';
  @ViewChildren('jobTable') jobTable: Table;
  @ViewChildren('pivotTable') pivotTable:Table;
  public barChartColors = [];

  //p-sidebar
  display: boolean = false;
  isPublished: boolean = false;
  eodDisplay: boolean = false;
  @Input() sidebarPosition: string = 'right';
  disable: boolean = false;
  date: Date;
  monthly: number;
  eodHeight: string = '';
  valueDate: any;
  companyList: any[] = [];
  profitCenterList: any[] = [];
  transactionTypeList: any[] = [];
  commodityList: any[] = [];
  tradeTypeList: any[] = [];
  eodCurrencyList: any[] = [];
  eodCurrency: string = '';
  eodUom: string = '';
  status: string = '';
  eodTemplateName: string = '';
  company: string = '';
  tradeType: string = '';
  commodity: string = '';
  transactionType: string = '';
  profitCenter: string = '';
  private formcontrolservice:FormControlServiceService = new FormControlServiceService();
  eodUomList: any[] = [];
  loadingOverlay: boolean = false;
  selectedCompany:string = "all";
  selectedCommodity:string = "all";
  selectedProfitcenter:string = "all";
  jobTableHeight: string = '';
  @Input() showTooltipOnValidationError: boolean = false;
  @Input() formControlAIO: FormControl;
  shipForm: FormGroup;
  EODTemplateId: string;
  @Input() historyDisplay: boolean = false;
  eodHistorydisplay: boolean = false;
  displayPosition: boolean;
  private inprogressRow:any;
  position: string;
  @Input() autoCalculateHeight: boolean = true;
  @Input() disabled: boolean = false;
//input box
  value: any;
  pattern2: any;
  @Input('value') val: any;
  pattern: any;
  list: any;
  statusValues: any[];
  @Input() defaultEdit: boolean = true;
  saveButtonLabel: string = 'Save';
  extrabuttons: any[] = ['Save And Run'];
  isUpdate:boolean = false;
  private uuid: any='';
  companyNotAll:boolean=false;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {xAxes: [{}], yAxes: [{display: true, ticks: {suggestedMin: 0}}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public chartLabelPC: Label[] = [];
  public chartLabelComm: Label[] = [];
  public chartLabelCP: Label[] = [];
  public barChartType: ChartType = 'bar';
  private sellOpenPosColor = '#7DA2BF';
  private buyOpenPosColor = '#F87B7f';
  private mtmColor = '#e15f1a';
  private realizedColor = '#008121';

  public barChartComm: ChartDataSets[] = [
    {
      label: 'MTM',
      backgroundColor: this.mtmColor,
      data: [],
      hoverBackgroundColor:this.mtmColor
    },
    {
      label: 'Realized',
      backgroundColor: this.realizedColor,
      data: [],
      hoverBackgroundColor:this.realizedColor
    }
  ];
  public barChartPC: ChartDataSets[] = [
    {
      label: 'MTM',
      backgroundColor: this.mtmColor,
      data: [],
      hoverBackgroundColor:this.mtmColor
    },
    {
      label: 'Realized',

      backgroundColor: this.realizedColor,
      data: [],
      hoverBackgroundColor:this.realizedColor
    }
  ];
  public barChartCP: ChartDataSets[] = [
    {
      label: 'MTM',
      backgroundColor: this.mtmColor,
      data: [],
      hoverBackgroundColor:this.mtmColor
    },
    {
      label: 'Realized',
      backgroundColor: this.realizedColor,
      data: [],
      hoverBackgroundColor:this.realizedColor
    }
  ];

  public barChartPosComm: ChartDataSets[] = [
    {
      label: 'Sell Open',
      backgroundColor: this.sellOpenPosColor,
      data: [],
      hoverBackgroundColor:this.sellOpenPosColor
    },
    {
      label: 'Buy Open',
      backgroundColor: this.buyOpenPosColor,
      data: [],
      hoverBackgroundColor:this.buyOpenPosColor
    },
    {
      label: 'Close',
      backgroundColor: this.realizedColor,
      data: [],
      hoverBackgroundColor:this.realizedColor
    }
  ];
  public barChartPosPC: ChartDataSets[] = [
    {
      label: 'Sell Open',
      backgroundColor: this.sellOpenPosColor,
      data: [],
      hoverBackgroundColor:this.sellOpenPosColor
    },
    {
      label: 'Buy Open',
      backgroundColor: this.buyOpenPosColor,
      data: [],
      hoverBackgroundColor:this.buyOpenPosColor
    },
    {
      label: 'Close',
      backgroundColor: this.realizedColor,
      data: [],
      hoverBackgroundColor:this.realizedColor
    }
  ];
  public barChartPosCP: ChartDataSets[] = [
    {
      label: 'Sell Open',
      backgroundColor: this.sellOpenPosColor,
      data: [],
      hoverBackgroundColor:this.sellOpenPosColor
    },
    {
      label: 'Buy Open',
      backgroundColor: this.buyOpenPosColor,
      data: [],
      hoverBackgroundColor:this.buyOpenPosColor
    },
    {
      label: 'Close',
      backgroundColor: this.realizedColor,
      data: [],
      hoverBackgroundColor:this.realizedColor
    }
  ];


  includedCharacters: any[] = ['_', '/'];
  formSaveFlag: boolean = false;
  collapsed: boolean = true;
  pivotTableHeight: string = '';
  selectedGraphMode: string = 'position';
  posGraph: boolean = false;
  jobDetails: any[] = [];
  graphData: any[] = [];
  selectedJobforDashboard: any;
  isRunning: boolean = false;
  tradetypedisable:boolean = false;
  isPublishedTriggered: boolean = false;

  constructor(public commonService: CommonService, private messageService: MessageService, public breadCrumbService: BreadcrumbService, private  route: Router,private masterCommonService: MasterService) {
  }

  ngOnInit(): void {
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.RISK_MAP_KEY, '/eod');
    this.getLoadData();
    //EOD Template Header
    this.statusValues = [
      {label: 'Active', value: 'Active'},
      {label: 'Inactive', value: 'Inactive'}
    ];

    this.eodCols = [
      {field: 'eodTemplateName', header: 'EOD Job Name', primary: true},
      {field: 'createdBy', header: 'Created By'},
      {field: 'executedBy', header: 'Executed By'},
      {field: 'runType', header: 'Run Type'},
      {field: 'lastRunDate', header: 'Last Run Date', date: true},
      {field: 'lastRunStatus', header: 'Last Run Status'},
      {field: 'viewHistory', header: ''}
    ];

    this.eodDetailCols = [
      {field: 'profitcenter', header: 'Profit Center', primary: true},
      {field: 'tradeType', header: 'Trade Type'},
      {field: 'commodity', header: 'Commodity'},
      {field: 'buyOpenPosition', header: 'Buy Open Position',qty:true},
      {field: 'sellOpenPosition', header: 'Sell Open Position',qty:true},
      {field: 'closedPosition', header: 'Closed Position',qty:true},
      {field: 'mtmPnl', header: 'MTM PnL', curr:true},
      {field: 'closedPnl', header: 'Closed PnL', curr:true},
      {field: 'realisedPnl', header: 'Realized PnL', curr:true},
      {field: 'freightCost', header: 'Freight Cost', curr:true},
      {field: 'financeCost', header: 'Finance Cost', curr:true},
      {field: 'insuranceCost', header: 'Insurance Cost', curr:true},
      {field: 'taxCost', header: 'Tax Cost', curr:true},
      {field: 'otherCost', header: 'Other Cost', curr:true},
      {field: 'totalCost', header: 'Cost', curr:true},
      {field: 'nbv', header: 'Net Book Value', curr:true}
    ];
    this.initInternalForm();
    this.initializeGraph();

    //Trade Type In DropDown
    this.transactionTypeList = [
      new KeyValue('Buy', 'BUY'),
      new KeyValue('Sell', 'SELL')
    ];
    this.getEodData();

    this.eodCurrencyList = this.commonService.getCurrencyList();
    // this.startListenerForJob();
    this.getEodTemplateDetails();

    //eodUOMList
    this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getuomdatabyuomtype?tenantId='
        +this.commonService.getFromStorage('tenantId') + '&uomType=' + 'mass').subscribe((next: any) => {
      this.eodUomList=this.commonService.createListFromObject(next,'name','name',true);
      this.commonService.getJSONByURL(environment.base_url + '/api/unitofmeasurement/v1/getuomdatabyuomtype?tenantId='
          +this.commonService.getFromStorage('tenantId') + '&uomType=' + 'volume').subscribe((next: any) => {
        let x=this.commonService.createListFromObject(next,'name','name',true);
        x.forEach(e=>{ if(e.label!='Select'){ this.eodUomList.push(e); }  });
      });
    });
  }

  loadData() {
    this.tradetypedisable = false;
    this.saveButtonLabel='Save'
    this.extrabuttons = ['Save and Run'];
    this.uuid = null;
    this.isUpdate = false;
    let _this = this;
    _this.openCreateNewReportForm();
  }

  getLoadData() {
    let _this = this;
    this.commonService.getLoadData('commodity',['config','data'], ['company',[{key:'name', value:'trade_type_ind'}]])
        .subscribe((next:any) => {
          _this.companyList.push({label: 'Select', value: ''});
          _this.companyList.push({label: 'All', value: 'All'});
          let x=_this.masterCommonService.getListFromArray(next['company'],false,true);
          x.forEach(e=>{
          if(e.label!='Select'){
          _this.companyList.push(e);
          }
          })
      _this.tradeTypeList = _this.masterCommonService.getListFromArray(next['trade_type_ind'],false,false);
    });
  }

  @HostListener("window:resize", [])
  public onResize() {
    if (this.secondLevel){
      this.calculatePivotHeight();
    }else{
      this.calculateJobTableHeight();
    }
  }

  calculateJobTableHeight() {
    let offsetTop = this.jobTable['first']['el']['nativeElement']['offsetTop'];
    let offsetHeight = this.jobTable['first']['el']['nativeElement']['offsetParent']['offsetHeight'];
    this.jobTableHeight = offsetHeight - (offsetTop + 20) + 'px';
  }


  calculatePivotHeight() {
    let offsetTop = this.pivotTable['first']['el']['nativeElement']['offsetTop'];
    let offsetHeight = this.pivotTable['first']['el']['nativeElement']['offsetParent']['offsetHeight'];
    this.pivotTableHeight = offsetHeight - (offsetTop + 20) + 'px';
  }

  getFilterValue(val: Event) {

  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  planRefresh() {
    this.selectedJobs = [];
    this.eodData = [];
    this.getEodData();
  }

  //On Clicking EOD Job Template Name
  onPrimaryClick(rowData) {
    if(rowData['lastRunStatus'] === 'FAILED') {
      this.showToast('Last run for EOD job '+rowData['eodTemplateName']+' is not successfull.');
    } else if(rowData['lastRunStatus'] === 'Not Executed') {
      this.showToast('EOD job '+rowData['eodTemplateName']+' not executed. Run EOD Job first');
    } else  {
      this.selectedJobforDashboard = rowData;
      this.loadingOverlay = true;
      this.secondLevelTitle = rowData['eodTemplateName'];
      this.secondLevel = true;
      this.selectedGraphMode = 'm2m';
      setTimeout(() => {
        this.loadingOverlay = false;
      }, 1000);
      let _this = this;
      this.commonService.getFromUrl(environment.base_url + '/api/eodDashboard/v1/eodDashboardJobDetail?eodJobName=' + rowData['eodTemplateName'] + '&eodRunDate=' + rowData['lastRunValueDate']+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        _this.jobDetails = next;
        _this.isPublished = _this.jobDetails['published'];
        _this.getEodReportAndGraphData(rowData['eodTemplateName'],rowData['lastRunValueDate'],_this.jobDetails['published']);
      });
    }
  }

  private getEodReportAndGraphData(eodJobName:string,eodRunDate,published:boolean) {
    let _this = this;
    _this.commonService.getFromUrl(environment.base_url + '/api/eodDashboard/v1/eodReportAggregated?eodJobName=' + eodJobName + '&eodRunDate=' + eodRunDate+'&tenantId='+this.commonService.getFromStorage('tenantId')+'&published='+published).subscribe((next: any) => {
      _this.eodDetailData = next;
    });

    _this.commonService.getFromUrl(environment.base_url + '/api/eodDashboard/v1/eodDashboardGraphStaging?eodJobName=' + eodJobName + '&eodRunDate=' + eodRunDate+'&tenantId='+this.commonService.getFromStorage('tenantId')+'&published='+published).subscribe((next: any) => {
      _this.graphData = next;
    });
  }

  changeRouteToBack() {
    this.secondLevel = false;
    this.initializeGraph();
  }

//EOD Template Details
  getEodData() {
    this.eodData = [];
    this.commonService.getJSONByURL(environment.base_url + '/api/eodTemplate/v1/geteodjobs?tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
      this.eodData = next;
      this.calculateJobTableHeight();
    });
  }

  //This block is contains the required steps to be done after EOD Save or EOD runAndSave
  postEODSave() {
    this.eodFormGroup.controls['company'].setValue('');
    this.eodFormGroup.controls['eodTemplateName'].setValue('');
    this.eodFormGroup.controls['tradeType'].setValue('');
    this.eodFormGroup.controls['eodCurrency'].setValue('');
    this.eodFormGroup.controls['eodUom'].setValue('');
    this.formSaveFlag = false;
    this.getEodData();

  }

  showToast(msg, severity: string = 'info') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

//Create New EOD SideBar


  private openCreateNewReportForm() {
    this.eodFormGroup.controls['company'].setValue('');
    this.eodFormGroup.controls['eodTemplateName'].setValue('');
    this.eodFormGroup.controls['tradeType'].setValue('');
    this.eodFormGroup.controls['eodCurrency'].setValue('');
    this.eodFormGroup.controls['eodUom'].setValue('');
    this.eodFormGroup.controls['status'].setValue('Active');
    this.selectedCompany = 'all';
    this.selectedCommodity = 'all';
    this.selectedProfitcenter = 'all';
    this.display = true;
  }


  closeEod() {
    this.display = false;
    this.eodDisplay = false;
  }

  closeInputForm() {
    this.display = false;
    this.initInternalForm();
  }

  showDialogBox(formSave: boolean = false) {
    if (formSave) {
      this.formSaveFlag = true;
      this.eodDisplay = true;
      this.getEodTemplateDetails();
    } else {
      if (this.selectedJobs.length > 0 && this.selectedJobs.length < 2) {
        this.formSaveFlag = false;
        this.eodDisplay = true;
      } else {
        this.showToast("First select an EOD Job, to run the EOD Report. You’ve not selected any EOD Job.");
      }
    }
  }

  getEodTemplateDetails() {
    this.eodTemplateName = this.eodFormGroup.controls['eodTemplateName'].value;
    this.company = this.eodFormGroup.controls['company'].value;
    this.tradeType = this.eodFormGroup.controls['tradeType'].value;
    this.eodCurrency = this.eodFormGroup.controls['eodCurrency'].value;
    this.eodUom = this.eodFormGroup.controls['eodUom'].value;
    this.status = this.eodFormGroup.controls['status'].value;
  }

  runEOD() {
    if (this.valueDate === undefined || this.valueDate === null) {
      this.showToast("Please enter Value date to run EOD", 'error');
    } else {
      this.isRunning = true;
      let invokePayload: {};
      if (this.formSaveFlag) {
        invokePayload = {
          eodValueDate: this.commonService.convertDatetoUTC(this.valueDate),
          eodJobName: this.eodTemplateName,
          profitcentre: this.profitCenter,
          company: this.company,
          commodity: this.commodity,
          tradeType: this.tradeType,
          eodCurrency: this.eodCurrency,
          eodUom: this.eodUom,
          status:this.status,
          runBy:this.commonService.getFromStorage('userName')
        }
      } else {
        invokePayload = {
          eodValueDate: this.commonService.convertDatetoUTC(this.valueDate),
          eodJobName: this.selectedJobs[0]['eodTemplateName'],
          company: this.getFromSelectedJob('company'),
          profitcentre: this.getFromSelectedJob('profitCentre'),
          commodity: this.getFromSelectedJob('commodity'),
          tradeType: this.selectedJobs[0]['tradeType'],
          eodCurrency: this.selectedJobs[0]['eodCurrency'],
          eodUom: this.selectedJobs[0]['eodUom'],
          status:this.status,
          runBy:this.commonService.getFromStorage('userName')
        };
      }
      this.commonService.postWithProgress(environment.base_url + '/api/jobService/v1/invokeJob?tenantId='+this.commonService.getFromStorage('tenantId'), invokePayload).subscribe(next => {
        if(next.type === HttpEventType.Sent) {
          this.inprogressEodRunObject[invokePayload['eodJobName']] = {status:"In Progress",history:{}};
        }
      }, (error: HttpErrorResponse) => {
        this.isRunning = false;
        if (error.status === 200) {
          this.showToast( 'EOD ' + invokePayload['eodJobName'] + ' is  initiated  successfully. For more details on step execution , please see “View history”.');
          this.eodDisplay = false;
          this.selectedJobs = [];
          this.postEODSave();
        } else {
          this.showToast("Error : " + error.status + " " + "Failed to run EOD, Please contact system administrator", 'error');
          this.selectedJobs = [];
        }
        this.removeFromInProgressObject(invokePayload['eodJobName']);
      });

      this.eodDisplay = false;
      this.isRunning = false;
      this.selectedJobs = [];
    }
  }

  onFormCancel() {
    this.eodDisplay = false;
  }

  showHistory(rowData: any) {
    this.eodHistoryData = rowData;
    this.eodHistorydisplay = true;
  }


  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

  isDisable() {
    return !(this.selectedJobs.length === 1);
  }


  switchValue(value) {
    this.disable = value['checked'];
  }

  closeEodHistory(value: any) {
    this.eodHistorydisplay = false;
  }

  private removeFromInProgressObject(jobName) {
    if(this.inprogressEodRunObject !== null && this.inprogressEodRunObject !== undefined && Object.keys(this.inprogressEodRunObject).includes(jobName)) {
      delete this.inprogressEodRunObject[jobName];
    }
  }

  private getFromSelectedJob(key:any) {
    let value = this.selectedJobs[0][key];
    if(value === null || value === undefined || value === '') {
      value = 'all';
    }
    return value;
  }

  getValueDate(valueDate: any) {
    let dateString = new Date(valueDate).toISOString();
    return dateString.substr(0, dateString.length - 5);
  }

  graphCollapse() {
    this.collapsed = !this.collapsed;
    if (this.collapsed === false) {
      this.generateGraphForPC();
      this.generateGraphForComm();
      this.generateGraphForCP();
    } else {
      this.initializeGraph();
    }
    this.calculatePivotHeight();
  }


  generateGraphForPC() {
    //Profit Center
    this.graphData['profitcenter'].forEach(value => {
      this.chartLabelPC.push(value['code']);
    });

    this.graphData['profitcenter'].forEach(value => {
      this.barChartPC[0]['data'].push(value['m2mpnl']);
      this.barChartPC[1]['data'].push(value['realizedpnl']);
      this.barChartPosPC[0]['data'].push(value['buyOpenPos']);
      this.barChartPosPC[1]['data'].push(value['sellOpenPos']);
      this.barChartPosPC[2]['data'].push(value['closepose']);
    });
  }

  generateGraphForComm() {
    //Commodity
    this.graphData['commodity'].forEach(value => {
      this.chartLabelComm.push(value['code']);
    });
    this.graphData['commodity'].forEach(value => {
      this.barChartComm[0]['data'].push(value['m2mpnl']);
      this.barChartComm[1]['data'].push(value['realizedpnl']);
      this.barChartPosComm[0]['data'].push(value['buyOpenPos']);
      this.barChartPosComm[1]['data'].push(value['sellOpenPos']);
      this.barChartPosComm[2]['data'].push(value['closepose']);
    });
  }

  generateGraphForCP() {
    //Counterparty
    this.graphData['counterparty'].forEach(value => {
      this.chartLabelCP.push(value['code']);
    });
    this.graphData['counterparty'].forEach(value => {
      this.barChartCP[0]['data'].push(value['m2mpnl']);
      this.barChartCP[1]['data'].push(value['realizedpnl']);
      this.barChartPosCP[0]['data'].push(value['buyOpenPos']);
      this.barChartPosCP[1]['data'].push(value['sellOpenPos']);
      this.barChartPosCP[2]['data'].push(value['closepose']);
    });
  }

  changeGraphView(mode: string) {
    if (mode === 'position') {
      this.posGraph = true;
    } else {
      this.posGraph = false;
    }
    this.selectedGraphMode = mode;
  }

  initializeGraph() {
    let m2mStructForPC = [
      {
        label: 'MTM',
        backgroundColor: this.mtmColor,
        data: [],
        hoverBackgroundColor:this.mtmColor
      },
      {
        label: 'Realized',
        backgroundColor: this.realizedColor,
        data: [],
        hoverBackgroundColor:this.realizedColor
      }
    ];

    let m2mStructForComm = [
      {
        label: 'MTM',
        backgroundColor: this.mtmColor,
        data: [],
        hoverBackgroundColor:this.mtmColor
      },
      {
        label: 'Realized',
        backgroundColor: this.realizedColor,
        data: [],
        hoverBackgroundColor:this.realizedColor
      }
    ];

    let m2mStructForCP = [
      {
        label: 'MTM',
        backgroundColor: this.mtmColor,
        data: [],
        hoverBackgroundColor:this.mtmColor
      },
      {
        label: 'Realized',
        backgroundColor: this.realizedColor,
        data: [],
        hoverBackgroundColor:this.realizedColor
      }
    ];

    let positionStructForPC = [
      {
        label: 'Buy Open',
        backgroundColor: this.buyOpenPosColor,
        data: [],
        hoverBackgroundColor:this.buyOpenPosColor
      },
      {
        label: 'Sell Open',
        backgroundColor: this.sellOpenPosColor,
        data: [],
        hoverBackgroundColor:this.sellOpenPosColor
      },
      {
        label: 'Close',
        backgroundColor: this.realizedColor,
        data: [],
        hoverBackgroundColor:this.realizedColor
      }
    ];

    let positionStructForComm = [
      {
        label: 'Buy Open',
        backgroundColor: this.buyOpenPosColor,
        data: [],
        hoverBackgroundColor:this.buyOpenPosColor
      },
      {
        label: 'Sell Open',
        backgroundColor: this.sellOpenPosColor,
        data: [],
        hoverBackgroundColor:this.sellOpenPosColor
      },
      {
        label: 'Close',
        backgroundColor: this.realizedColor,
        data: [],
        hoverBackgroundColor:this.realizedColor
      }
    ];

    let positionStructForCP = [
      {
        label: 'Buy Open',
        backgroundColor: this.buyOpenPosColor,
        data: [],
        hoverBackgroundColor:this.buyOpenPosColor
      },
      {
        label: 'Sell Open',
        backgroundColor: this.sellOpenPosColor,
        data: [],
        hoverBackgroundColor:this.sellOpenPosColor
      },
      {
        label: 'Close',
        backgroundColor: this.realizedColor,
        data: [],
        hoverBackgroundColor:this.realizedColor
      }
    ];
    this.barChartPC = m2mStructForPC;
    this.barChartComm = m2mStructForComm;
    this.barChartCP = m2mStructForCP;
    this.barChartPosPC = positionStructForPC;
    this.barChartPosComm = positionStructForComm;
    this.barChartPosCP = positionStructForCP;
    this.chartLabelPC = []
    this.chartLabelComm = [];
    this.chartLabelCP = [];
    this.posGraph = false;
    this.collapsed = true;
    this.selectedGraphMode = '';
  }

  getDate(date) {
    if(date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
      let options = {day: 'numeric', month: 'long', year: 'numeric'};
      let newDate = new Date(date);
      return newDate.toLocaleDateString("en-US", options);
    }
    return '';
  }

  onlyUnique(value) {
    return [...new Set(value)];
  }

  getCurrency(data,col) {

    return this.commonService.getFormatedNumber(data[col.field]) + ' '+data['eodCurrency'];      //For Demo purpose only, replace USD with actual Currency UOM
  }

  getUom(data,col) {
    return this.commonService.getFormatedNumber(data[col.field]) +' '+data['eodUom'];      //For Demo purpose only, replace MT with actual Quantity UOM
  }

  expandRow(rowData, rowIndex) {
    let paylaod = {
      eodJobName:this.selectedJobforDashboard['eodTemplateName'],
      eodRunDate:this.jobDetails['valueDate'],
      profitcenter:rowData['profitcenter'],
      published:this.jobDetails['published']
    };
    this.commonService.post(environment.base_url + '/api/eodDashboard/v1/eodReportCalulation?tenantId='+this.commonService.getFromStorage('tenantId'),paylaod).subscribe((next: any) => {
      this.eodDetailData[rowIndex]['groupedData'] = next;
    });
  }

  publishEOD() {
    if(!this.jobDetails['published']) {
      let _this = this;
      _this.isPublishedTriggered = true;
      this.commonService.getFromUrl(environment.base_url + '/api/eodTemplate/v1/publishEodReport?eodJobName=' + this.selectedJobforDashboard['eodTemplateName'] + '&eodRunDate='+this.selectedJobforDashboard['lastRunValueDate']+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) =>{
        _this.showToast("Published successfully !");
        _this.onPrimaryClick(_this.selectedJobforDashboard);
        _this.isPublishedTriggered = false;
      },(error:HttpErrorResponse) =>{
        _this.showToast(_this.commonService.getHttpErrorMessage(error,"EOD Publish"),'error');
        _this.isPublishedTriggered = false;
      });
    }
  }

  getClassForStatus(status: string,jobName) {
    if(status.toUpperCase() === 'FAILED') {
      return 'failedLabel';
    } else if(status.toUpperCase() === 'SUCCESS' || status.toUpperCase() === 'COMPLETED') {
      return 'successLabel';
    } else if(status.toUpperCase() === 'NOT EXECUTED') {
      return 'notExecutedLabel';
    }  else if(status.toUpperCase() === 'PUBLISHED') {
      return 'publishedLabel';
    } else if(status.toUpperCase() === 'IN PROGRESS') {
      if(this.inprogressEodRunObject === null || this.inprogressEodRunObject === undefined || this.inprogressEodRunObject[jobName] === null || this.inprogressEodRunObject[jobName] === undefined) {
        this.inprogressEodRunObject[jobName] = {status:"In Progress",history:{}};
      }
      this.inprogressEodRunObject[jobName]['status'] = status;
      return 'inProgressLabel';
    } else if(status.toUpperCase() === 'COMPLETED WITH WARNING(S)') {
      return 'completeWithWarningLabel';
    }
  }

  getRowDataValue(rowData, col: any) {
    if(col.field === 'profitcenter') {
      let val:string = rowData[col.field];
      if(val !== undefined && val !== null && val.length > 0) {
        val = val.replace(new RegExp(',','g'),', ');
      }
      return val;
    }
    return rowData[col.field];
  }

    downloadEodRawData() {
      let _this = this;
      let jobName = this.selectedJobforDashboard['eodTemplateName'];
      let valueDate = this.jobDetails['valueDate'];
      this.commonService.getJSONByURL(environment.base_url+"/api/eodTemplate/v1/getEodStaging?eodJobName="+jobName+'&eodValueDate='+valueDate+'&tenantId='+this.commonService.getFromStorage('tenantId')+'&published='+this.jobDetails['published']).subscribe((next:any) => {
        _this.exportFile(next,jobName,valueDate);
      },(error:HttpErrorResponse) => {
        _this.showToast("Failed to download EOD Raw Data.",'error');
      });
    }

  private exportFile(rowData:any,jobName,runDate) {
    let sheets = {};
    let data = this.replaceKeyWithName(rowData['eodStagingList']);
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    sheets['Physical'] = worksheet;
    if(rowData['futureEodStagingList'] !== undefined && rowData['futureEodStagingList'] !== null && rowData['futureEodStagingList'].length > 0) {
      let futureData = this.replaceKeyWithNameForFuture(rowData['futureEodStagingList']);
      let worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(futureData);
      sheets['Future'] = worksheet1;
    }

    if(rowData['fxEodStagingList'] !== undefined && rowData['fxEodStagingList'] !== null && rowData['fxEodStagingList'].length > 0) {
      let fxData = this.replaceKeyWithNameForFX(rowData['fxEodStagingList']);
      let worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(fxData);
      sheets['FX'] = worksheet2;
    }

    let workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(sheets),
      Props: {Author: 'Taomish India Pvt. Ltd.'}
    };
    let excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer,jobName+'_'+runDate,'xlsx')
  }

  public saveAsExcelFile(buffer: any, fileName: string, extension, middleFormat: string = '_EOD_Raw_Data_'): void {
    const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8'});
    fs.saveAs(data, fileName + middleFormat + new Date().getTime() + '.' + extension);
    this.showToast("Downloading Excel...", 'info')
  }

  private nullCheck(value) {
    if(value !== undefined && value !== null) {
      return value;
    }
    return '-';
  }

  private replaceKeyWithName(data: any[] = []) {
    let newRowData = [];
    let _this =this;
    data.forEach(function (rowData) {
      let obj = {
        "EOD Job Name":_this.nullCheck(rowData['eodJobName']),
        "EOD Run Date":_this.commonService.getFormattedDate(rowData['eodRunDate'],"dd-MM-yyyy"),
        "Trade Id":_this.nullCheck(rowData['tradeId']),
        "Transaction Type":rowData['tradeTransactionType'] === 'BUY'?'Buy':'Sell',
        "Obligation Id":_this.nullCheck(rowData['obligationID']),
        "Company":_this.nullCheck(rowData['company']),
        "Profit Center":_this.nullCheck(rowData['profitcenter']),
        "Commodity":_this.nullCheck(rowData['commodity']),
        "Counterparty":_this.nullCheck(rowData['counterparty']),
        "EOD UOM":_this.nullCheck(rowData['eodUOM']),
        "EOD Currency":_this.nullCheck(rowData['eodCurrency']),
        "M2M Settlement":_this.nullCheck(rowData['mtmSettlement']),
        "M2M PnL":_this.nullCheck(rowData['mtmPnl']),
        "Buy Open Position":_this.nullCheck(rowData['buyOpenPosition']),
        "Sell Open Position":_this.nullCheck(rowData['sellOpenPosition']),
        "Closed Position":_this.nullCheck(rowData['closedPosition']),
        "Realized Position":_this.nullCheck(rowData['realizedPosition']),
        "Close PnL":_this.nullCheck(rowData['closedPnlToday']),
        "Realized PnL":_this.nullCheck(rowData['realisedPnlToday']),
        "PnL Monthly":_this.nullCheck(rowData['pnlMonthly']),
        "PnL Yearly":_this.nullCheck(rowData['pnlYearly']),
        "Freight Cost":_this.nullCheck(rowData['freightCost']),
        "Finance Cost":_this.nullCheck(rowData['financeCost']),
        "Insurance Cost":_this.nullCheck(rowData['insuranceCost']),
        "Tax Cost":_this.nullCheck(rowData['taxCost']),
        "Other Cost":_this.nullCheck(rowData['otherCost']),
        "Cost":_this.nullCheck(rowData['totalCost']),
        "Net Margin":_this.nullCheck(rowData['mtmPnl'])+_this.nullCheck(rowData['realisedPnlToday']), //Calculate here
        "Price Exposure":_this.nullCheck(rowData['priceExposure']),
        "FX Exposure":_this.nullCheck(rowData['fxExposure']) === 0?0:_this.nullCheck(rowData['fxExposure']) + ' '+ _this.nullCheck(rowData['priceSettlementCurrency']),
        "FX Rate":_this.nullCheck(rowData['fxRate']),
        "Total Contract QTY":_this.nullCheck(rowData['totalContractQty']),
        "Plan Id":_this.nullCheck(rowData['planId']),
        "FX Allocation Status":_this.nullCheck(rowData['fxAllocationStatus']),
        "Price Allocation Status":_this.nullCheck(rowData['priceAllocationStatus']),
        "Obligation Status":_this.nullCheck(rowData['obligationStatus']),
        "Trade Quantity":_this.nullCheck(rowData['tradeQuantity']),
        "Planned Quantity":_this.nullCheck(rowData['plannedQuantity']) === 0?0:_this.nullCheck(rowData['plannedQuantity'])+ ' '+ _this.nullCheck(rowData['quantityUOM']),
        "Origin":_this.nullCheck(rowData['origin']),
        "Brand":_this.nullCheck(rowData['brand']),
        "Grade":_this.nullCheck(rowData['grade']),
        "Season":_this.nullCheck(rowData['season']),
        "Bulk/Container":_this.nullCheck(rowData['bulkPacked']),
        "incoterm":_this.nullCheck(rowData['incoterm']),
        "Counter party":_this.nullCheck(rowData['counterparty']),
        "Location":_this.nullCheck(rowData['location']),
        "Company Currency":_this.nullCheck(rowData['companyCurrency']),
        "Trade Load Location":_this.nullCheck(rowData['tradeLoadLocation']),
        "Trade Discharge Location":_this.nullCheck(rowData['tradeDischargeLocation']),
        "Delivery StartDate":_this.nullCheck(rowData['deliveryStartDate']),
        "Delivery EndDate":_this.nullCheck(rowData['deliveryEndDate']),
        "LoadPort":_this.nullCheck(rowData['loadPort']),
        "DischargePort":_this.nullCheck(rowData['dischargePort']),
        "LaycanDate":_this.nullCheck(rowData['laycanDate']),
        "Trade Settlement Price":_this.nullCheck(rowData['tradeSettlementPrice']) + ' '+ _this.nullCheck(rowData['priceSettlementCurrency']) + '/'+ _this.nullCheck(rowData['quantityUOM']),
        "Trade price":_this.nullCheck(rowData['price']) + ' '+ _this.nullCheck(rowData['priceCurrency']) + '/'+ _this.nullCheck(rowData['priceUOM']),
        "Price Type":_this.nullCheck(rowData['priceType']),
        "Prov Trade Price":_this.nullCheck(rowData['provTradePrice']) === 0 || rowData['provTradePrice'] === null?0:_this.nullCheck(rowData['provTradePrice']) + ' '+ _this.nullCheck(rowData['provPriceCurrency']) + '/'+ _this.nullCheck(rowData['provPriceUOM']),
        "Stock Type":_this.nullCheck(rowData['stockType']) ,
        "Stock Quantity":_this.nullCheck(rowData['stockQuantity']),
        "Stock Price":_this.nullCheck(rowData['stockPrice']) === 0 || rowData['stockPrice'] === null?0:_this.nullCheck(rowData['stockPrice']) + ' '+ _this.nullCheck(rowData['stockCurrency']) + '/'+ _this.nullCheck(rowData['stockUOM']),
        "Storage Location":_this.nullCheck(rowData['storageLocation']),
        "Actual Load Quantity":_this.nullCheck(rowData['actualLoadQuantity']) === 0 || rowData['actualLoadQuantity'] === null?0:_this.nullCheck(rowData['actualLoadQuantity'])+' '+ _this.nullCheck(rowData['quantityUOM']),
        "Actual Unload Quantity":_this.nullCheck(rowData['actualUnloadQuantity']) === 0?0:_this.nullCheck(rowData['actualUnloadQuantity'])+' '+ _this.nullCheck(rowData['quantityUOM']),
        "Actualised Origin":_this.nullCheck(rowData['actualOrigin']),
        "Actual Grade":_this.nullCheck(rowData['actualGrade']),
        "Actual Brand":_this.nullCheck(rowData['actualBrand']),
        "Invoice Id":_this.nullCheck(rowData['invoiceId']),
        "Invoiced Amount":_this.nullCheck(rowData['invoicedAmount']),
        "Market SettlementPrice":_this.nullCheck(rowData['marketSettlementPrice']),
        "Future MarketSettlementPrice":_this.nullCheck(rowData['futureMarketSettlementPrice']),
        "Basis MarketSettlementPrice":_this.nullCheck(rowData['basisMarketSettlementPrice']),
        "Contract Month":_this.nullCheck(rowData['contractMonth']),
        "MTM Index":_this.nullCheck(rowData['mtmindex']),
        "Basis MTMIndex":_this.nullCheck(rowData['basisMtmIndex']),
        "Realised Date":_this.nullCheck(rowData['realisedDate']),
        "Total Premium Discount": _this.nullCheck(rowData['totalPremiumDiscount']),
        "Total Netted Cost": _this.nullCheck(rowData['totalNettedCost']),
        "Total Added Cost": _this.nullCheck(rowData['totalAddedCost'])
      };
      newRowData.push(obj);
    });
    return newRowData;
  }

  private replaceKeyWithNameForFuture(data: any[] = []) {
    let newRowData = [];
    let _this = this;
    data.forEach(function (rowData) {
      let obj = {
        "EOD Job Name":_this.nullCheck(rowData['eodJobName']),
        "EOD Run Date":_this.commonService.getFormattedDate(rowData['eodRunDate'],"dd-MM-yyyy"),
        "Future Trade Id":_this.nullCheck(rowData['tradeId']),
        "Transaction Type":(rowData['tradeTransactionType'] === 'BUY'?'Long':'Short'),
        "Company":_this.nullCheck(rowData['company']),
        "Profit Center":_this.nullCheck(rowData['profitcenter']),
        "Commodity":_this.nullCheck(rowData['commodity']),
        "Counterparty":_this.nullCheck(rowData['counterparty']),
        "EOD UOM":_this.nullCheck(rowData['eodUOM']),
        "EOD Currency":_this.nullCheck(rowData['eodCurrency']),
        "M2M PnL":_this.nullCheck(rowData['mtmPnl']),
        "Buy Open Position":_this.nullCheck(rowData['buyOpenPosition']),
        "Sell Open Position":_this.nullCheck(rowData['sellOpenPosition']),
        "Closed Position":_this.nullCheck(rowData['closedPositon']),
        "Close PnL":_this.nullCheck(rowData['closedPnl']),
        "Net Margin":_this.nullCheck(rowData['mtmPnl'])+_this.nullCheck(rowData['closedPnl']), //Calculate here
        "Closed Date":_this.nullCheck(rowData['closeDate']),
        "Trade Type":_this.nullCheck(rowData['tradeType']),
        "Trade Date":_this.nullCheck(rowData['tradeDate']),
        "Trader Name":_this.nullCheck(rowData['traderName']),
        "Future Index":_this.nullCheck(rowData['futureIndex']),
        "Exchange":_this.nullCheck(rowData['exchange']),
        "Expiry Month":_this.nullCheck(rowData['expiryMonth']),
        "Lot":_this.nullCheck(rowData['lot']),
        "Match Lot":_this.nullCheck(rowData['matchLot']),
        "Lot Size":_this.nullCheck(rowData['lotSize']),
        "Trade Price":_this.nullCheck(rowData['tradePrice']) + ' ' +_this.nullCheck(rowData['uom']),
        "Expiry Date":_this.nullCheck(rowData['expiryDate']),
        "Clearing Broker":_this.nullCheck(rowData['clearingBroker']),
        "Clearing Broker A/C":_this.nullCheck(rowData['clearingBrokerAccount']),
        "Execution Broker":_this.nullCheck(rowData['executionBroker']),
        "Brokerage Fee":_this.nullCheck(rowData['brokerageFee']),
        "Clearing Fee":_this.nullCheck(rowData['clearingFee']),
        "ExchangeFor":_this.nullCheck(rowData['exchangeFor']),
        "Tag":_this.nullCheck(rowData['tag']),
        "NettingStatus":_this.nullCheck(rowData['nettingStatus']),
        "Expired Status":_this.nullCheck(rowData['expiredStatus']),
        "MTM Index":_this.nullCheck(rowData['mtmIndex']),
        "Future Price":_this.nullCheck(rowData['futurePrice']),
        "Future PriceLots":_this.nullCheck(rowData['futurePriceLots']),
        "QuantityLot":_this.nullCheck(rowData['quantityLot']),
        "QuantityMT":_this.nullCheck(rowData['quantityMT']),
        "Currency":_this.nullCheck(rowData['currency']),
        "Market Price":_this.nullCheck(rowData['marketPrice']),
        "Netted Date":_this.nullCheck(rowData['nettedDate'])
      };
      newRowData.push(obj);
    });
    return newRowData;
  }

  private replaceKeyWithNameForFX(data: any[] = []) {
    let newRowData = [];
    let _this = this;
    data.forEach(function (rowData) {
      let obj = {
        "EOD Job Name":_this.nullCheck(rowData['eodJobName']),
        "EOD Run Date":_this.commonService.getFormattedDate(rowData['eodRunDate'],"dd-MM-yyyy"),
        "FX Trade Id":_this.nullCheck(rowData['fxTradeId']),
        "Transaction Type":(rowData['tradeTransactionType'] === 'BUY'?'Buy':'Sell'),
        "Trade Type":_this.nullCheck(rowData['fxTradeType']),
        "Company":_this.nullCheck(rowData['company']),
        "Profit Center":_this.nullCheck(rowData['profitcenter']),
        "EOD UOM":_this.nullCheck(rowData['eodUOM']),
        "EOD Currency":_this.nullCheck(rowData['eodCurrency']),
        "M2M PnL":_this.nullCheck(rowData['mtmPnl']),
        "M2M PnL Currency":_this.nullCheck(rowData['mtmPnlCurrency']),
        "Realized PnL":_this.nullCheck(rowData['realisedPnl']),
        "Realized PnL Currency":_this.nullCheck(rowData['realisedPnlCurrency']),
        "Net Margin":_this.nullCheck(rowData['mtmPnl'])+_this.nullCheck(rowData['realisedPnl']),
        "FX Trade Type":_this.nullCheck(rowData['fxTradeType']),
        "Trade Date":_this.nullCheck(rowData['tradeDate']),
        "Counter party":_this.nullCheck(rowData['counterparty']),
        "Fx Pair":_this.nullCheck(rowData['fxPair']),
        "Value Date":_this.nullCheck(rowData['valueDate']),
        "Notional Amount":_this.nullCheck(rowData['notionalAmount']),
        "FX Rate":_this.nullCheck(rowData['rate']),
        "Settlement Currency":_this.nullCheck(rowData['settlementCurrency']),
        "Settlement Date":_this.nullCheck(rowData['settlementDate']),
        "Planned NotionalValue":_this.nullCheck(rowData['plannedNotionalValue']),
        "Buy Amount":_this.nullCheck(rowData['buyAmount']),
        "Sell Amount":_this.nullCheck(rowData['sellAmount']),
        "Buy AmountCurrency":_this.nullCheck(rowData['buyAmountCurrency']),
        "Sell AmountCurrency":_this.nullCheck(rowData['sellAmountCurrency']),
        "Fx MarketIndex":_this.nullCheck(rowData['fxMarketIndex'])
      };
      newRowData.push(obj);
    });
    return newRowData;
  }

    onClickTemplate(value: any) {
      this.loadingOverlay = true;
      this.selectedGraphMode = 'm2m';
      setTimeout(() => {
        this.loadingOverlay = false;
      }, 1000);
      let eodRunDate= value['eodRunDate'];
      let _this = this;
      this.commonService.getFromUrl(environment.base_url + '/api/eodDashboard/v1/eodDashboardJobDetail?eodJobName=' + value['eodJobName'] + '&eodRunDate=' + eodRunDate+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe((next: any) => {
        _this.jobDetails = next;
        _this.getEodReportAndGraphData(value['eodJobName'],eodRunDate,_this.jobDetails['published']);
      });
    }


  onSelectAllProfitCenter() {
    this.selectedProfitcenter = 'all';
  }

  onSelectAllCommodity() {
    this.selectedCommodity = 'all';
  }

  onValueChangeCommodity(value: any) {
    if(value === null || value === undefined || value === '') {
      value = 'all';
    }
    this.formcontrolservice.setValue('commodity',value);
  }

  onValueChangeCompany(value: any) {
    if(value === null || value === undefined || value === '') {
      value = 'all';
    }
    this.formcontrolservice.setValue('company',value);
  }

  onValueChangeProfitcenter(value: any) {
    if(value === null || value === undefined || value === '') {
      value = 'all';
    }
    this.formcontrolservice.setValue('profitCentre',value);
  }

  private startListenerForJob() {
    // let json = {};
    // let _this = this;
    // let stompClient = this.webSocketService.connect();
    // stompClient.connect({}, frame => {
    //   stompClient.subscribe('/topic/eodstephistory', stephistory => {
    //     json = JSON.parse(stephistory['body']);
    //     if(json['stepStatus'].toUpperCase() === 'FAILED' || Object.keys(_this.inprogressEodRunObject[json['eodJobName']]['history']).length === 12) {
    //       _this.planRefresh();
    //       _this.removeFromInProgressObject(json['eodJobName']);
    //       _this.refresh = true;
    //     }
    //     _this.inprogressEodRunObject[json['eodJobName']]['history'][json['stepStatusHistory']['step']] = json['stepStatusHistory'];
    //     if(_this.inprogressRow !== null && _this.inprogressRow !== undefined) {
    //       _this.inprogressRow['stepStatus'] = Object.values(_this.inprogressEodRunObject[_this.inprogressRow['eodJobName']]['history']).sort(this.sortByTime);
    //     }
    //   });
    // });
  }

  getStatusValue(rowData: any, col: any) {
    if(this.inprogressEodRunObject !== null && this.inprogressEodRunObject !== undefined && Object.keys(this.inprogressEodRunObject).includes(rowData['eodTemplateName'])) {
      return this.inprogressEodRunObject[rowData['eodTemplateName']]['status'].toString().toUpperCase();
    }
    return rowData[col.field].toString().toUpperCase()
  }

  onLiveEventFound(rowData: any) {
    let _this = this;
    let keys:any[] = Object.keys(_this.inprogressEodRunObject[rowData['eodJobName']]['history']);
    rowData['stepStatus'].forEach(function (row) {
      if(!keys.includes(row['step'])) {
        _this.inprogressEodRunObject[rowData['eodJobName']]['history'][row['step']] = row;
      }
    });
    this.inprogressRow = rowData;
  }

  onRefreshComplete() {
    this.refresh = false;
  }

  sortByTime( a, b ) {
    if ( a['startDateTime'] < b['startDateTime']){
      return -1;
    }
    if ( a['startDateTime'] > b['startDateTime']){
      return 1;
    }
    return 0;
  }

  private initInternalForm() {
    this.eodFormGroup = this.formcontrolservice
        .addControl('eodTemplateName','',[Validators.required])
        .addControl('company','all',[])
        .addControl('tradeType','',[Validators.required])
        .addControl('eodCurrency','',[Validators.required])
        .addControl('eodUom','',[Validators.required])
        .addControl('status','')
        .addControl('value').build();
    this.createValidationSection();
  }

  private createValidationSection() {
    this.validationSections = {};
    this.validationSections['Criteria'] = [
      {field:'eodTemplateName',header:'Eod Job Name'},
      {field:'company',header:'Company'},
      {field:'tradeType',header:'Trade Type'},
      {field:'eodCurrency',header:'EOD Currency'},
      {field:'eodUom',header:'EOD Uom'},
    ];
  }

  async saveEodAndRun(value: any, run: boolean) {
    let payload = {};
    let _this = this;
    if(run) {
      payload = value['values'];
    } else {
      payload = value;
    }
    payload['tenantId'] = this.commonService.getFromStorage('tenantId');
    payload['createdBy'] = this.commonService.getFromStorage('userName');
    payload['updatedBy'] = this.commonService.getFromStorage('userName');
    payload['timeFrom'] = this.commonService.getFromStorage('TFrom');
    payload['uuid'] = this.uuid;
    payload['runType'] = 'Ad-hoc';

    if(this.isUpdate){
      payload['company'] = this.formcontrolservice.getValue('company');
      await this.commonService.post(environment.base_url + '/api/eodTemplate/v1/updateEodJob?tenantId='+this.commonService.getFromStorage('tenantId'), payload).subscribe(next => {
        this.display = false;
        if (run) {
          this.showDialogBox(true);
        } else {
          this.getEodData();
          this.selectedJobs = [];
          _this.showToast('EOD job details have been Updated Id: ' + next['eodTemplateName']);
          _this.postEODSave();
          _this.formcontrolservice.reset();

        }
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
      });













    }else{
      await this.commonService.post(environment.base_url + '/api/eodTemplate/v1/createEodJob?tenantId='+this.commonService.getFromStorage('tenantId'), payload).subscribe(next => {
        this.display = false;
        if (run) {
          this.showDialogBox(true);
        } else {
          this.getEodData();
          this.selectedJobs = [];
          _this.showToast('EOD job details have been saved Id: ' + next['eodTemplateName']);
          _this.postEODSave();
          _this.formcontrolservice.reset();
        }
      }, (error: HttpErrorResponse) => {
        this.messageService.add({severity: 'error', summary: this.commonService.getHttpErrorMessage(error, undefined)});
      });
    }
  }

  changecompany(value: any) {
    if(value.value!='All'){
      this.companyNotAll=true;
      this.commonService.getFromUrl(environment.base_url + '/api/company/v1/getcompanybyname?tenantId='+this.commonService.getFromStorage('tenantId') + '&name=' +value.value).subscribe((next: any) => {
        this.formcontrolservice.setValue('eodCurrency',next['currency']);
        this.formcontrolservice.setValue('eodUom',next['baseuom']);
      });
    }
    else{  this.companyNotAll=false; }
  }

   onclickedit(value: any) {
    var selectedjob = this.selectedJobs[0];
    this.eodFormGroup.reset();
    this.formcontrolservice.setValue('company',selectedjob['company']);
    this.formcontrolservice.setValue('tradeType',selectedjob['tradeType']);
    this.formcontrolservice.setValue('eodTemplateName',selectedjob['eodTemplateName']);
    this.formcontrolservice.setValue('eodCurrency',selectedjob['eodCurrency']);
    this.formcontrolservice.setValue('eodUom',selectedjob['eodUom']);
    this.formcontrolservice.setValue('status',selectedjob['status']);
    this.eodFormGroup.controls['company'].disable()
    this.saveButtonLabel='Update'
    this.extrabuttons = [];
    this.uuid = selectedjob['uuid']
    this.isUpdate = true;
    this.tradetypedisable = true;
    this.display = true;
  }
}
